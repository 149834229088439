/* alegreya-sans-regular - latin-ext_latin_cyrillic */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-regular.svg#AlegreyaSans') format('svg'); /* Legacy iOS */
}
/* alegreya-sans-italic - latin-ext_latin_cyrillic */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-italic.svg#AlegreyaSans') format('svg'); /* Legacy iOS */
}
/* alegreya-sans-500 - latin-ext_latin_cyrillic */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-500.svg#AlegreyaSans') format('svg'); /* Legacy iOS */
}
/* alegreya-sans-700 - latin-ext_latin_cyrillic */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-700.svg#AlegreyaSans') format('svg'); /* Legacy iOS */
}
/* alegreya-sans-500italic - latin-ext_latin_cyrillic */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-500italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-500italic.svg#AlegreyaSans') format('svg'); /* Legacy iOS */
}
/* alegreya-sans-700italic - latin-ext_latin_cyrillic */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/alegreya-sans-v14-latin-ext_latin_cyrillic-700italic.svg#AlegreyaSans') format('svg'); /* Legacy iOS */
}