body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
} */

.cls-1 {
  fill: #c3c9b9;
}
.cls-2 {
  fill: #f5eaca;
}

/* .react-transform-component {
  width: unset !important;
  height: unset !important;
} */

.gmnoprint {
  display: none !important;
}

.gm-style-cc {
  display: none !important;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.carousel img {
  width: unset;
}

.carousel .control-dots .flickity-page-dot:only-child {
  display: none;
}

.MuiDialogContent-root {
  margin: 0 !important;
}

::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.flickity-page-dot:focus {
  box-shadow: unset !important;
}

/* audio::-webkit-media-controls-mute-button {
  display: none !important;
}
audio::-webkit-media-controls-volume-slider {
  display: none !important;
} */

video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

/* video::-webkit-media-controls-current-time-display {
  display: none !important;
}
video::-webkit-media-controls-time-remaining-display {
  display: none !important;
} */
video::-webkit-media-controls-mute-button {
  display: none !important;
}
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none !important;
}
video::-webkit-media-controls-volume-slider {
  display: none !important;
}
video::-webkit-media-controls-volume-control-container {
  display: none !important;
}
video::-webkit-internal-media-controls-overflow-button {
  display: none !important;
}

/* video::-webkit-media-controls-enclosure {
  display: none !important;
} */

video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

/* video::-webkit-media-controls-play-button {
  display: none !important;
} */

/* video::-webkit-media-controls-timeline {
  margin: 0 180px 34px 128px;
  opacity: 1 !important;
  height: 7px !important;
  padding: 0 !important;
} */

/* video::-webkit-media-controls-panel {
  display: none !important;
} */

/* video::-webkit-media-controls-overlay-enclosure {
  display: none !important;
}

video::cue {
  color: white;
  font-family: Mulish;
  font-size: 40px;
  line-height: 42px;
  background-color: rgba(0, 0, 0, 0) !important;
}

video::-webkit-media-text-track-display {
  width: 90% !important;
  left: 5% !important;
  bottom: 60px !important;
  top: unset !important;
  background: rgba(0, 0, 0, 0.3) !important;
} */

/* input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 8.4px;
  background: transparent;
  cursor: pointer;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  border-radius: 1.3px;
  -webkit-appearance: none;
  transition: all 0.4s ease;
}

input[type='range']::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background: rgba(235, 10, 36, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-left: -1px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: transparent;
}

progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
  width: 100%;
  height: 8.4px;
  pointer-events: none;
  position: absolute;
  top: 0;
}

progress::-webkit-progress-bar {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
}

progress::-webkit-progress-value {
  background: rgba(235, 10, 36, 1);
  border-radius: 2px;
}

progress::-moz-progress-bar {
  border: 1px solid rgba(235, 10, 36, 1);
  background: rgba(235, 10, 36, 1);
} */
